.light-theme .headermain.relative .navbar-nav li a{
    color: black !important;
} 

.light-theme .headermain.relative .colorbackmain.light{
    color: black !important;
}


.NavbarTitle1 {
    width: fit-content;
    font-size: 25px;
    font-family: system-ui,sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    margin-top: 12px;
    /* -webkit-text-stroke: 1px white;
    background: 
      linear-gradient(-60deg,#0000 45%,#000 0 55%,#0000 0) 
      0/300% 100% no-repeat text;
    animation: l3 2s linear infinite; */
  }

  /* .light-theme .navbar.relative .NavbarTitle1{
    color: black;
    -webkit-text-stroke: 1px white;
    background: 
      linear-gradient(-60deg,#0000 45%,#000 0 55%,#000 0) 
      0/300% 100% no-repeat text;
  } */

  .light-theme .NavbarTitle1{
    background: linear-gradient(80deg, #A56FFF, #E94EC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  .dark-theme .NavbarTitle1{
    background: -webkit-linear-gradient(top, 
            rgba(213,173,109,1) 0%, 
            rgba(213,173,109,1) 26%, 
            rgba(226,186,120,1) 35%, 
            rgba(163,126,67,1) 45%, 
            rgba(145,112,59,1) 61%, 
            rgba(213,173,109,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }

  .NavbarTitle1:before {
    content: "DATADNA";
  }
  @keyframes l3 {
    0%{background-position: 100%}
  }


  .dark-theme .navbar .dropdown .theme-toggle.dark{
    color: white !important;
  }

  .dark-theme .headermain.fixed .dropdown .theme-toggle.dark{
    color: black !important;
  }

  .dark-theme .headermain.fixed .nav-item a{
    color: black !important;
  }

  /* .dark-theme .headermain.fixed .NavbarTitle1{
    color: #0000;
    -webkit-text-stroke: 1px white;
    background: 
      linear-gradient(-60deg,#0000 45%,#000 0 55%,#0000 0) 
      0/300% 100% no-repeat text;
    animation: l3 2s linear infinite;
  } */

  @media (max-width: 768px){
    .theme-toggle {
      margin-left: 190px;
      
  }

  .light-theme .headermain.relative .navbar-toggler .icon{
    color: black;
  }

  .light-theme .headermain.fixed .navbar-toggler .icon{
    color: black;
  }

  .dark-theme .headermain.relative .navbar-toggler .icon{
    color: white;
  }

  .dark-theme .headermain.fixed .navbar-toggler .icon{
    color: white;
  }

  }