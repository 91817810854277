.light-theme .contactpage{
    background: linear-gradient(180deg, #eae4ff, #ffffff);
}



.dark-theme .contactpage{
    background: linear-gradient(to bottom, #300d5e, #1a1a1a);
}

.contactpage a{
    text-decoration: none;

}

.light-theme .contactpage .contactcontent a{
    background: linear-gradient(80deg, #A56FFF, #E94EC4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dark-theme .contactpage .contactcontent a{
    color: #D5AD6D; /* Fallback color */
background: -webkit-linear-gradient(top, 
            rgba(213,173,109,1) 0%, 
            rgba(213,173,109,1) 26%, 
            rgba(226,186,120,1) 35%, 
            rgba(163,126,67,1) 45%, 
            rgba(213,173,109,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.light-theme .contactpage .contactarea button{
    background: linear-gradient(80deg, #A56FFF, #E94EC4);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
}

.dark-theme .contactpage .contactarea button{
    background: -webkit-linear-gradient(top, 
    rgba(213,173,109,1) 0%, 
    rgba(213,173,109,1) 26%, 
    rgba(226,186,120,1) 35%, 
    rgba(163,126,67,1) 45%, 
    rgba(145,112,59,1) 61%, 
    rgba(213,173,109,1) 100%);
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
}

.phone-input .PhoneInputInput {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  

  @media (max-width: 768px){
    .contactarea h1{
        text-align: center;
    }

    .contactbutton {
        margin-left: 32%;
    }
  }