
.ftco-footer {
  font-size: 16px;
  padding: 2em 0;
  
}
.ftco-footer .ftco-footer-logo {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.ftco-footer .ftco-footer-widget h2 {
  font-weight: bold;
  
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* .ftco-footer .ftco-footer-widget h2:after {
  position: absolute;
  bottom: -15px;
  left: 0;
  content: "";
  width: 70px;
  height: 1px;
  background: black;
} */

.ftco-footer .ftco-footer-widget ul li a span {
  /* color: white; */
}
.ftco-footer .ftco-footer-widget .btn-primary {
  /* background: black !important;
  border: 2px solid #fff !important; */
}
.ftco-footer .ftco-footer-widget .btn-primary:hover {
  /* background: black;
  border: 2px solid #fff !important; */
}
/* .ftco-footer p {
  color: white;
}
.ftco-footer a {
  color: white;
}
.ftco-footer a:hover {
  color: #fff;
} */
.ftco-footer .ftco-heading-2 {
  font-size: 17px;
  font-weight: 400;
  /* color: white; */
}
.ftco-footer .block-21 .text .heading {
  font-size: 16px;
  font-weight: 300;
}
/* .ftco-footer .block-21 .text .heading a {
  color: rgba(255, 255, 255, 0.9);
} */
.ftco-footer .block-21 .text .heading a:hover,
.ftco-footer .block-21 .text .heading a:active,
.ftco-footer .block-21 .text .heading a:focus {
  color: #fa5bdd;
}
.ftco-footer .block-21 .text .meta > div {
  display: inline-block;
  font-size: 12px;
  margin-right: 5px;
}
.ftco-footer .block-21 .text .meta > div a {
  color: gray;
}

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}
.ftco-footer-social li a {
  height: 50px;
  width: 50px;
  display: block;
  float: left;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  position: relative;
}
.ftco-footer-social li a svg {
  position: absolute;
  font-size: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ftco-footer-social li a i {
  position: absolute;
  font-size: 26px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ftco-footer-social li a:hover {
  color: #fff;
}


.social-button:hover {
  color: var(--pink);
  --fill-color: 100%;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 1);
}

.social-button::after {
  position: absolute;
  z-index: -1;
  content: "";
  inset: -3px;
  border-radius: inherit;

  background: conic-gradient(
    var(--primary-color) var(--fill-color),
    transparent var(--fill-color)
  );
  transition: var(--blue) 0.75s ease-in-out;
}

.fa-facebook-f{
  color: blue;
}

.fa-youtube {
  color: #FF0000;
}

.fa-instagram {
  color: #fccc63;
}


@media (max-width: 600px) {
  .ftco-footer h2{
    text-align: center;
  }

  .ftco-footer-social {
    align-items: center;
    text-align: center;
  }

  .ftco-footer ul{
    text-align: center;
  }
}

.ftco-footer a{
  text-decoration: none;
}

.light-theme .ftco-footer a{
  color: black !important;
}

.dark-theme .ftco-footer a{
  color: white !important;
}
.light-theme .ftco-footer span{
  color: black;
}

.dark-theme .ftco-footer span{
  color: white !important;
}

.dark-theme footer {
  background-color: #1a1a1a;
}


.container1 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container1 {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container1 {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container1 {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container1 {
    /* max-width: 1140px; */
    max-width: 100%;
  }
}