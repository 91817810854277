/* styles/Home.module.css */
.container {
    
    font-family: Arial, sans-serif;
    
  }

  .light-theme .homeheadergradient {
    background: linear-gradient(180deg, #eae4ff, #ffffff);

  }


  .light-theme .hero h1, .light-theme .hero h1{
    color: black;
  } 

  .dark-theme .hero h1,.dark-theme .hero p{
    color: white;
  }

  .dark-theme .homeheadergradient {
    background-color: #1a1a1a;
  }

 .backgroundcolorhm{
    /* background: linear-gradient(to bottom, #300d5e, #1a1a1a); */
    background-image: url('../Images/Hero\ 5.jpg');
    background-size: cover;
    background-position: center;
    
   
  }

  /* .dark-theme .backgroundcolorhm{
    background: linear-gradient(to bottom, #300d5e, #1a1a1a);
  } */

  /* .backgroundcolorhm::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  } */

  .Viewdemo {
    background-color: rgba(255, 255, 255, 0);
    padding: 1rem 2rem;
    border-radius: 5px;
    
    border-color: white;
    
  }

  .light-theme .Viewdemo {
    color: black;
  }

  .dark-theme .Viewdemo{
    color: white;
  }
  
  .hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    height: 645px;
   
    position: relative;
  }


  .hero .heroContent{
    width: 30% !important;
    bottom: 0 !important;
    position: relative;
    z-index: 2;
    color: white;
  }

  .light-theme .heroContent strong{
    color: black;
  }

  .dark-theme .heroContent strong{
    color: white;
  }

  .dark-theme .heroContent p{
    color: grey;
  }
  
  .heroContent h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .homepageclientle{
    font-size: 2rem;
    margin-bottom: 1rem;
    color: rgb(124, 52, 168);
    
  }
  
  .heroContent p {
    font-size: 0.8rem;
    margin-bottom: 2rem;
    color: grey;
  }
  
  .heroButtons button {
    margin-right: 1rem;
  }
  
  .heroImage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  .theme-toggle.light {
   
 
  }
  
.theme-toggle.dark {
  
    
}

.htheme-toggle {
    border-color: none;
    border: none;
    opacity: 1;
    margin-left: 10px;
}

.theme-toggle {
  border-color: none;
  border: none;
  opacity: 1;
  margin-left: 10px;
}

.container{
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1rem;
}

body.light-theme{
  color: black !important;
}

body.dark-theme{
  color: white !important;
}



.light-theme h1{
  font-family: "Lora", serif;
  font-weight: bold;
  background: linear-gradient(90deg, #a66fffb5, #e94ec5d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.light-theme h3{
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  background: linear-gradient(80deg, #A56FFF, #E94EC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.light-theme h2{
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  background: linear-gradient(80deg, #A56FFF, #E94EC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.light-theme .aboutspan {
  font-family: "Libre Baskerville", serif;
  font-weight: bold;
  background: linear-gradient(80deg, #A56FFF, #E94EC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dark-theme .aboutspan{
  font-family: "Libre Baskerville", serif;
  color: #D5AD6D; /* Fallback color */
background: -webkit-linear-gradient(top, 
            rgba(213,173,109,1) 0%, 
            rgba(213,173,109,1) 26%, 
            rgba(226,186,120,1) 35%, 
            rgba(163,126,67,1) 85%, 
         
            rgba(213,173,109,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.dark-theme h1{
  font-family: "Lora", serif;
  background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dark-theme h3{
  font-family: "Libre Baskerville", serif;
  color: #D5AD6D; /* Fallback color */
background: -webkit-linear-gradient(top, 
            rgba(213,173,109,1) 0%, 
            rgba(213,173,109,1) 26%, 
            rgba(226,186,120,1) 35%, 
            rgba(163,126,67,1) 85%, 
         
            rgba(213,173,109,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.dark-theme h2{
  font-family: "Libre Baskerville", serif;
  color: #D5AD6D; /* Fallback color */
background: -webkit-linear-gradient(top, 
            rgba(213,173,109,1) 0%, 
            rgba(213,173,109,1) 26%, 
            rgba(226,186,120,1) 35%, 
            rgba(163,126,67,1) 45%, 
            rgba(213,173,109,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

p{
  font-family: "Merriweather", serif;
}