
  .light-theme .divclientlogs{
    background-color: transparent;
  }

  .dark-theme .divclientlogs{
    padding: 10px;
    /* background: rgba(255, 255, 255, 0.221);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(1px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    
    z-index:4 !important; */
  }

  .dark-theme .clientlogobg{
    background-color: #eae4ff;
  }

  .dark-theme .clientlogos .divclientlogs img{
    filter: contrast(200%) !important;
    z-index: 12 !important;
  }

  /* .dark-theme .clientlogos{
    background-color: #cfc09f;
  } */

  /* .dark-theme .clientlogos {
    background: rgba(255, 255, 255, 0.31);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  
  } */