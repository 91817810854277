/* Services.css */
.coreofferinghome {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .coreofferinghome .service-card {
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .coreofferinghome .service-card:hover {
    transform: translateY(-5px);
  }
  
  .coreofferinghome .service-icon {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 10px;
  }

  .coreofferinghome .service-icon img{
    width: 50px;
  }
  
  .coreofferinghome .service-title {
    font-size: 16px;
    font-weight: bold;
  
  }
  
  .coreofferinghome .service-description {
    font-size: 14px;
   
  }
  
  .learn-more {
    margin-top: 10px;
    padding: 8px 16px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .learn-more:hover {
    background: #0056b3;
  }

  @media (max-width: 600px) {
    .coreofferinghome{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      padding: 20px;
    }
  }

.core-offering-home{
  height: auto;
}

.core-offering-home h1{
 
}