.custom-services-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .custom-services-container .custom-service-card {
   
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
    background: transparent;
  }

  .light-theme .custom-services-container .custom-service-card{
    border: 2px solid #a66fff;
  }
  
  .dark-theme .custom-services-container .custom-service-card{
    border: 2px solid #cfc09f;
  }
  
  .custom-services-container .custom-service-card:hover {
    transform: translateY(-5px);
  }
  
  .custom-services-container .custom-service-card .icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  .custom-services-container .custom-service-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .custom-services-container .custom-service-card p {
    font-size: 14px;
    color: #555;
  }
  
  .custom-services-container .learn-more-text {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
  }

  .custom-services-container .icon img{
    width: 40px;
  }

  .custom-services-container p{
    text-align: justify;
  }

  .light-theme .custom-services-container p{
    color: black;
  }

  .dark-theme .custom-services-container p{
    color: white;
  }

  @media (max-width: 600px) {
    .custom-services-container{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      padding: 20px;
    }
  }