.herocards .card1{
    background: rgba(255, 255, 255, 0.089);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px;
    width: 300px;
    border-radius: 10px;
  
    color: white;
}

.herocards h3{
    font-size: 17px;
}


.herocards p{
    font-size: 14px;
}

.herocards .card2{
    background: rgba(255, 255, 255, 0.089);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px;
    width: 300px;
    margin: 10px;
  
    border-radius: 10px;
    color: white;
}

.herocards .card3{
    background: rgba(255, 255, 255, 0.089);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 300px;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    color: white;
}

.herocards{
    position: absolute;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    bottom: 30px !important;
    z-index: 2;
    margin-left: 5%;
}

.dark-theme .herocards{
    color: black;
}

@media (max-width: 767.98px) {
    .herocards{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 12%;
    }
}

@media (min-width: 768px) { 
    .herocards .card1{
        margin-left: 80px;
        margin-right: 150px;
    }

    .herocards .card2{
        margin-right: 150px;
    }
}