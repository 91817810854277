.customrequirements {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    text-align: center;
    padding: 20px;
  }
  
  .customrequirements .requirement-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
  }
  
  .customrequirements .requirement-card h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .customrequirements .requirement-card p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
    text-align: justify;
  }
  
  .customrequirements .requirement-card span {
    font-weight: bold;
    cursor: pointer;
    color: #007bff;
  }
  
  .customrequirements .customreqicon img{
    width: 40px;
  }

  .light-theme .customrequirements p{
    color: black;
  }

  .dark-theme .customrequirements p{
    color: white;
  }